import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Group, rem } from '@mantine/core';
import {
  FacebookIcon,
  FacebookShareButton,
  PinterestIcon,
  PinterestShareButton,
  TwitterShareButton,
  XIcon,
} from 'react-share';

import { ButtonIcon, Text } from 'shared/components/atoms';

const messages = {
  share: 'Udostępnij',
};

type SiteMeta = {
  url?: string;
  twitterNick?: string;
};

export interface ShareButtonsProps {
  url: string;
  title: string;
  image?: string;
  description?: string;
  size?: number;
}

export const ShareButtons = ({ url, image, title, description, size = 3 }: ShareButtonsProps) => {
  const data = useStaticQuery<{ site: { siteMetadata: SiteMeta } }>(graphql`
    query Metadata {
      site {
        siteMetadata {
          url
          twitterNick
        }
      }
    }
  `);
  const text = `${title}\n${description}\n`;
  const href = data.site.siteMetadata.url + url;

  return (
    <Group align="center" gap="md">
      <Text fz="xs" ff="heading" c="gray.9" fw="bold">
        {messages.share}
      </Text>
      <ButtonIcon component={FacebookShareButton} url={href} title={text} size={rem(size * 8)}>
        <FacebookIcon round />
      </ButtonIcon>
      <ButtonIcon
        component={TwitterShareButton}
        url={href}
        title={text}
        via={data.site.siteMetadata.twitterNick}
        size={rem(size * 8)}
      >
        <XIcon round />
      </ButtonIcon>
      {image && (
        <ButtonIcon
          component={PinterestShareButton}
          title={text}
          url={href}
          media={image}
          description={title}
          size={rem(size * 8)}
        >
          <PinterestIcon round />
        </ButtonIcon>
      )}
    </Group>
  );
};
